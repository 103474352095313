import { useState, ReactNode, SyntheticEvent, useRef, useEffect, forwardRef, useCallback } from 'react';

import { InView } from 'react-intersection-observer';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useMediaQuery, useTheme } from '@mui/material';

import { TTabsSize, TTabRowVariant } from '@/types';

import {
	tabsContentStyles,
	getTabsListWrapStyles,
	getTabsWrapperStyles,
	tabsStyles,
	getTabStyles,
	getTabPanelStyles,
} from './TAGTabs.styles';

interface TabPanelProps {
	children?: ReactNode;
	value: string;
	currentValue: string;
	role: 'default' | 'navigation';
	withContentInnerGap?: boolean;
	isSmallScreen?: boolean;
}

const CustomTabPanel = forwardRef<HTMLDivElement, TabPanelProps>(
	({ children, value, currentValue, role, withContentInnerGap, isSmallScreen }, ref) => {
		return (
			<div
				role="tabpanel"
				hidden={role === 'default' && currentValue !== value}
				aria-labelledby={`tag-tab-${value}`}
				ref={ref}
				style={getTabPanelStyles({ withContentInnerGap, isSmallScreen })}
			>
				{children}
			</div>
		);
	}
);

CustomTabPanel.displayName = 'CustomTabPanel';

const a11yProps = (value: string) => {
	return {
		id: `tab-${value}`,
		'aria-controls': `tab-tabpanel-${value}`,
	};
};

interface TAGTabsProps {
	tabs: {
		label: string;
		value: string;
		href?: string;
		content: ReactNode;
	}[];
	role?: 'default' | 'navigation';
	variant?: 'fullWidth' | 'standard' | 'scrollable';
	centered?: boolean;
	dataTestId?: string;
	tabRowVariant?: TTabRowVariant;
	tabsSize?: TTabsSize;
	withContentInnerGap?: boolean;
}

export default function TAGTabs({
	tabs,
	role = 'default',
	variant = 'standard',
	centered,
	dataTestId = '',
	tabRowVariant = 'Standard',
	tabsSize = 'Small',
	withContentInnerGap = false,
}: TAGTabsProps) {
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
	const [currentValue, setCurrentValue] = useState(tabs[0].value);
	const [isTabsFixed, setIsTabsFixed] = useState(false);
	const tabsWrapNode = useRef<HTMLDialogElement>(null);

	const handleChange = (_: SyntheticEvent, newValue: string) => {
		setCurrentValue(newValue);
	};

	const handleScroll = useCallback(() => {
		if (tabsWrapNode.current) {
			const blockOffsetTop = tabsWrapNode.current.offsetTop;
			if (window.scrollY >= blockOffsetTop) {
				setIsTabsFixed(true);
			} else {
				setIsTabsFixed(false);
			}
		}
	}, []);

	const setInView = (inView: boolean, entry: IntersectionObserverEntry) => {
		if (role !== 'navigation') return;
		if (inView) {
			setCurrentValue(entry.target.getAttribute('id') as string);
		}
	};

	useEffect(() => {
		if (role !== 'navigation') return;
		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, [handleScroll, role]);

	return (
		<Box sx={tabsContentStyles} ref={tabsWrapNode}>
			<Box sx={getTabsListWrapStyles({ tabRowVariant, isSmallScreen })}>
				<Box sx={getTabsWrapperStyles({ tabRowVariant, isTabsFixed })}>
					<Tabs
						value={currentValue}
						onChange={handleChange}
						centered={centered}
						variant={variant}
						sx={tabsStyles}
					>
						{tabs.map(({ label, href, value }) => (
							<Tab
								key={value}
								sx={getTabStyles({ tabsSize, tabRowVariant, isSmallScreen })}
								{...a11yProps(value)}
								{...(href ? { href } : {})}
								value={value}
								label={label}
								data-test-id={`${dataTestId}_${value}`}
							/>
						))}
					</Tabs>
				</Box>
			</Box>
			{tabs.map(({ content, value }) => (
				<InView onChange={setInView} threshold={0.5} key={value} id={value}>
					<CustomTabPanel
						value={value}
						currentValue={currentValue}
						role={role}
						withContentInnerGap={withContentInnerGap}
						isSmallScreen={isSmallScreen}
					>
						{content}
					</CustomTabPanel>
				</InView>
			))}
		</Box>
	);
}
