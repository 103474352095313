import { TTabsSize, TTabRowVariant } from '@/types';

export const tabsContentStyles = { width: '100%' };

export const getTabsListWrapStyles = (params?: { tabRowVariant?: TTabRowVariant; isSmallScreen?: boolean }) => ({
	borderBottom: 1,
	borderColor: 'divider',
	...(params?.tabRowVariant === 'Filled' && {
		backgroundColor: 'neutrals.neutral',
		paddingLeft: params?.isSmallScreen ? 0 : '5%',
		paddingRight: params?.isSmallScreen ? 0 : '5%',

		'& .MuiTabs-indicator': {
			display: 'none',
		},
	}),
});

export const getTabsWrapperStyles = (params?: { tabRowVariant?: TTabRowVariant; isTabsFixed?: boolean }) => ({
	position: params?.isTabsFixed ? 'fixed' : 'static',
	background: 'white',
	top: 0,
	left: 0,
	width: '100%',
	zIndex: 1,
	borderBottom: 1,
	borderColor: 'divider',
	marginBottom: '-1px',
	...(params?.tabRowVariant === 'Filled' && {
		backgroundColor: 'neutrals.neutral',
	}),
});

export const tabsStyles = { marginBottom: '-1px' };

export const getTabStyles = (params?: {
	tabsSize?: TTabsSize;
	tabRowVariant?: TTabRowVariant;
	isSmallScreen?: boolean;
}) => ({
	textTransform: 'none',
	...((params?.tabsSize === 'Medium' || params?.tabsSize === 'Small' || params?.isSmallScreen) && {
		padding: '0.75rem 1rem',
		fontSize: '1rem',
		lineHeight: '1.625rem',
	}),
	...(params?.tabsSize === 'Large' &&
		!params?.isSmallScreen && {
			padding: '1.5rem 1rem',
			fontSize: '2rem',
			lineHeight: '2.125rem',
		}),
	...(params?.tabRowVariant === 'Filled' && {
		backgroundColor: 'neutrals.neutral',
		color: 'text.primary',
		borderBottom: 1,
		borderColor: 'divider',
		'&.Mui-selected': {
			color: 'text.interactive',
			backgroundColor: 'neutrals.white',
			border: 1,
			borderBottom: 'none',
			borderColor: 'divider',
			borderRadius: '1.5rem 1.5rem 0 0',
			marginBottom: '-1px',

			'&:first-child': {
				...(params?.isSmallScreen && { borderRadius: '0 1.5rem 0 0', borderLeft: 0 }),
			},
			'&:last-child': {
				...(params?.isSmallScreen && { borderRadius: '1.5rem 0rem 0 0', borderRight: 0 }),
			},
		},
	}),
});

export const getTabPanelStyles = (params?: { withContentInnerGap?: boolean; isSmallScreen?: boolean }) => ({
	...(params?.withContentInnerGap && !params?.isSmallScreen && { padding: '0 6rem' }),
});
