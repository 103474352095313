import { AccountLineDTO, AccountLineDTOList } from '@/rest/__generated/revspringApi';
import { logWarning, logError } from '@/utils/miscUtils';
import { httpClient } from '@/utils';

export interface ProvidersLineLength {
	Onsite: number;
	Telecart: number;
}

// Using -1 to indicate a potential error state
export const defaultProvidersLineLength: ProvidersLineLength = {
	Onsite: -1,
	Telecart: -1,
};

// Look up the values of provider and peopleOnLine in each entry returned
// by the endpoint, create a simple Object, eg
// { someProviderName: 10 }
// and merge them all together. Using the default above protects against
// errors resulting from the provider names changing without warning
type ProvidersLineLengthFn = (arr: AccountLineDTO[]) => ProvidersLineLength;
const getProvidersLineLength: ProvidersLineLengthFn = (arr) =>
	arr.reduce(
		(acc, item) => Object.assign(acc, { [item?.provider ?? '']: item?.peopleOnLine ?? -1 }),
		defaultProvidersLineLength
	);

export default async function getPeopleInLine(revspringAccountId: string): Promise<ProvidersLineLength> {
	if (revspringAccountId) {
		try {
			const data = await httpClient.request<AccountLineDTOList>({
				url: `Account/`,
				params: {
					RevSpringAccountId: revspringAccountId,
				},
				requestName: 'getPeopleInLine',
			});

			return getProvidersLineLength(data?.accountLinesDTOS ?? []);
		} catch (err) {
			logWarning(`Could not fetch people in line`);
			if (err instanceof Error) {
				logError(`REVSPRING_PEOPLE_IN_LINE_FAILURE: ${err.message}`);
			}
			return defaultProvidersLineLength;
		}
	} else {
		return defaultProvidersLineLength;
	}
}
