import { Facility, FacilityBrand, BffBrandType } from '@/types/generated';

import { ILocationCardProps } from './LocationCard';

export function getScheduleButtonUrl(
	schedulingPathFromConfig: string,
	facilityBrand: FacilityBrand | BffBrandType,
	location: Facility,
	locationScheduling?: ILocationCardProps['locationScheduling']
) {
	if (!location.brand && facilityBrand === FacilityBrand.AspenDental) {
		return globalThis.location?.origin + `/${schedulingPathFromConfig}/?fc=` + `${location?.code}`;
	}

	if (location.brand === FacilityBrand.AspenDental) {
		if (location?.scheduling?.schedulingUrl) {
			if (location?.scheduling?.schedulingUrl.includes('fc')) {
				return location?.scheduling?.schedulingUrl;
			}
			return location?.scheduling?.schedulingUrl?.concat('/?fc=', location?.code);
		}
		return globalThis.location?.origin + `/${schedulingPathFromConfig}/?fc=` + `${location?.code}`;
	}

	const schedulingSystem = locationScheduling && locationScheduling.schedulingSystem;

	if (schedulingSystem === 'Revspring') {
		if (location.brand === FacilityBrand.WellNow) {
			return `/schedule-appointment/?fc=${location.code}`;
		}
	}

	if (location.brand === FacilityBrand.ClearChoice) {
		return `${location?.scheduling?.schedulingUrl ?? '#'}?fc=CC_${location?.code}`;
	}

	return location?.scheduling?.schedulingUrl || '#';
}
